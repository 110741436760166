import React, { useState } from "react"
import SinglePageHeader from "../../components/SinglePageLayout"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, Navigation } from "swiper"
import { Lightbox } from "react-modal-image"
import ModernImg from "../../components/ModernImg"
import { useTranslation } from "gatsby-plugin-react-i18next"

const slides = [
  "/assets/img/qinq-hai/1.jpg",
  "/assets/img/qinq-hai/3.jpg",
  "/assets/img/qinq-hai/4.jpg",
  "/assets/img/qinq-hai/5.jpg",
  "/assets/img/qinq-hai/6.jpg",
  "/assets/img/qinq-hai/7.jpg",
  "/assets/img/qinq-hai/8.jpg",
  "/assets/img/qinq-hai/2.jpg",
]

SwiperCore.use([Pagination, Navigation])

export default function Csr({ data }) {
  const { t } = useTranslation()
  const [state, setState] = useState({ isModalOpen: false, activeImage: "" })

  return (
    <SinglePageHeader title={t("media")} bg="/assets/img/csr-banner.jpg">
      {state.isModalOpen && (
        <Lightbox
          hideDownload={true}
          hideZoom={true}
          medium={state.activeImage}
          large={state.activeImage}
          onClose={() => setState({ isModalOpen: false, activeImage: "" })}
        />
      )}
      <article className="ich-post ich-container">
        <h3 className="post-title">{t("qing-hai-project")}</h3>
        <p>{t("qing-hai-subtitle")}</p>
        <hr />
        <Swiper
          pagination={{ clickable: true }}
          navigation
          spaceBetween={50}
          slidesPerView={3}
          style={{ width: "100%", paddingBottom: 40 }}
          breakpoints={{
            // when window width is >= 375px
            375: {
              width: 375,
              slidesPerView: 1,
              spaceBetween: 0,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 2,
              spaceBetween: 10,
            },
          }}
        >
          {slides.map(slide => (
            <SwiperSlide
              key={slide}
              onClick={() =>
                setState({ isModalOpen: true, activeImage: slide })
              }
            >
              <ModernImg src={slide} alt="qing hai" style={{ height: 250 }} />
            </SwiperSlide>
          ))}
        </Swiper>
        <h6 style={{ marginTop: 20 }}>{t("qing-hai-title-1")}</h6>
        <p>{t("qing-hai-p1")}</p>
        <h6>{t("qing-hai-title-2")}</h6>
        <p>{t("qing-hai-p2")}</p>
        <p>{t("qing-hai-p3")}</p>
      </article>
    </SinglePageHeader>
  )
}
